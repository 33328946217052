import { Box, Typography } from "@mui/material";
import { FC } from "react";

export const EmptyList: FC = () => {
  return (
    <Box
      sx={{
        height: 70,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <Typography fontWeight={600}>EMPTY</Typography>
    </Box>
  );
};
