import httpClient from "../Lib/http-client";
import {
  QuestionFormFilterModel,
  QuestionFormModel,
  QuestionModel,
} from "../Pages/QuestionPage/models";
import { QUESTIONS_ENDPOINT } from "../constants/endpoint";

export const fetchQuestionsApi = (
  params?: QuestionFormFilterModel
): Promise<QuestionModel[]> => {
  return httpClient.get(QUESTIONS_ENDPOINT, {
    params: {
      ...(params?.srouceCategoryId
        ? { srouceCategoryId: params.srouceCategoryId }
        : {}),
      ...(params?.parentCategoryId
        ? { parentCategoryId: params.parentCategoryId }
        : {}),
      ...(params?.categoryId ? { categoryId: params.categoryId } : {}),
    },
  });
};

export const createQuestionApi = (
  body: QuestionFormModel
): Promise<QuestionModel> => {
  return httpClient.post(QUESTIONS_ENDPOINT, body);
};

export const updateQuestionApi = (
  body: QuestionFormModel
): Promise<QuestionModel> => {
  return httpClient.put(`${QUESTIONS_ENDPOINT}/${body.id}`, body);
};

export const deleteQuestionApi = (id?: number): Promise<void> => {
  return httpClient.delete(`${QUESTIONS_ENDPOINT}/${id}`);
};
