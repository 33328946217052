import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import _ from "lodash";
import { QuestionSueggestionModel } from "../../models";

export const SuggestionsForm: FC = () => {
  const {
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "suggestions",
  });

  const suggestions = watch("suggestions") as QuestionSueggestionModel[];

  return (
    <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <Stack direction={"row"} spacing={2}>
            <Controller
              control={control}
              name={`suggestions.${index}.content`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  value={field.value ?? ""}
                  error={_.has(errors, `suggestions[${index}].content`)}
                  fullWidth
                  label={`Suggestions ${index + 1}`}
                  variant="outlined"
                />
              )}
            />

            <Controller
              name={`suggestions.${index}.isTrue`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ width: 300 }}
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const indexHasTrue = suggestions.findIndex(
                          (x) => x.isTrue
                        );
                        if (indexHasTrue > -1) {
                          setValue(`suggestions.${indexHasTrue}.isTrue`, false);
                        }
                        console.log({ indexHasTrue });
                        field.onChange(event.target.checked);
                      }}
                    />
                  }
                  label="Is true"
                />
              )}
            />

            <Button type="button" color="error" onClick={() => remove(index)}>
              <DeleteOutlinedIcon />
            </Button>
          </Stack>
        </Box>
      ))}

      <Button
        variant="outlined"
        type="button"
        onClick={() => append({ content: "", isTrue: false })}
      >
        append
      </Button>
    </Stack>
  );
};
