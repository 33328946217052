import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { FC } from "react";

export const SkeletonListing: FC = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton animation="wave" height={40} />
      <Skeleton animation="wave" height={40} />
      <Skeleton animation="wave" height={40} />
    </Box>
  );
};
