import { QuestionFormModel } from "../../models";

export const defaultFormValues: QuestionFormModel = {
  title: "",
  headContent: "",
  content: "",
  description: "",
  response: "",
  coefficient: 1,
  duration: 30,
  srouceCategoryId: null!,
  parentCategoryId: null!,
  categoryId: null!,
  image: null,
  withSuggestions: false,
};
