import { useQuery } from "@tanstack/react-query";
import { FC, ReactNode, createContext, useContext } from "react";
import { fetchCategoriesApi } from "../../../Api/category.api";
import { SkeletonListing } from "../../../components/SkeletonListing";
import { CategoryModel } from "../../../models/category.model";

interface CategoryContextModel {
  refetch: VoidFunction;
  data: CategoryModel[];
}

const CategoryContext = createContext<CategoryContextModel>(null!);
export const useCategoryContext = () => useContext(CategoryContext);

const CategoryProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Queries
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["categories-query"],
    queryFn: () => fetchCategoriesApi(),
  });

  if (isLoading) {
    return <SkeletonListing />;
  }

  return (
    <CategoryContext.Provider
      value={{
        refetch: () => {
          refetch();
        },
        data: data ?? [],
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
