import Typography from "@mui/material/Typography";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Box, Button, Divider, IconButton, Stack } from "@mui/material";
import { FC, useState } from "react";
import { theme } from "../../../../Layouts/Theme";
import RootDialog from "../../../../components/RootDialog";
import { useCategoryContext } from "../../context";

import { useMutation } from "@tanstack/react-query";
import { deleteCategoryApi } from "../../../../Api/category.api";
import { CategoryFormModel } from "../../models";
import { CategoryForm } from "./components/CategoryForm";
import { IconButtonDelete } from "./components/DeleteButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./components/Styled";
import { SubCategoriesListing } from "./components/SubCategoriesListing";
import { EmptyList } from "./components/EmptyList";

const categoryFormValue: CategoryFormModel = {
  name: "",
  parentCategoryId: null,
};

export const Listing: FC = () => {
  const { data, refetch } = useCategoryContext();
  const [expanded, setExpanded] = useState<number | false>(false);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    defaultValues: CategoryFormModel;
  }>({
    open: false,
    defaultValues: categoryFormValue,
  });

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  /**
   * DEELETE CATEGORY MUTATION
   */
  const mutationDelete = useMutation({
    mutationKey: ["DeleteCategorypi"],
    mutationFn: (params: { id: number }) => {
      return deleteCategoryApi(params.id);
    },
  });
  /***********************************/

  return (
    <Stack direction={"column"} spacing={3}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey[200],
          height: 56.5,
          borderRadius: 0.75,
          px: 1.5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
          <Box>
            <Typography
              fontWeight={700}
              sx={{
                color: theme.palette.grey[700],
              }}
            >
              {data.length} items
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 0.5 }}
            onClick={() => {
              setDialogState((dialogState) => ({
                ...dialogState,
                open: true,
                defaultValues: {
                  ...categoryFormValue,
                },
              }));
            }}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>
      <Box>
        {data.map((item, index) => (
          <Accordion
            elevation={expanded === item.id ? 5 : 0}
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontWeight={700}
                  sx={{
                    color: theme.palette.grey[600],
                  }}
                >
                  {item.name}
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pr: 2,
                  mr: 2,
                }}
              >
                <Typography
                  fontWeight={400}
                  sx={{
                    color: theme.palette.grey[400],
                  }}
                >{`${item.children.length} Items`}</Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
              </Box>
              <Box>
                <Stack direction={"row"} spacing={1}>
                  <IconButton
                    size="small"
                    aria-label="delete"
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDialogState((dialogState) => ({
                        ...dialogState,
                        open: true,
                        defaultValues: {
                          ...categoryFormValue,
                          parentCategoryId: item.id,
                        },
                      }));
                    }}
                  >
                    <AddOutlinedIcon fontSize="medium" />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setDialogState((dialogState) => ({
                        ...dialogState,
                        open: true,
                        defaultValues: {
                          ...categoryFormValue,
                          id: item.id,
                          name: item.name,
                          parentCategoryId: item.id,
                          icon: item.icon,
                        },
                      }));
                    }}
                    size="small"
                    aria-label="delete"
                    color="primary"
                  >
                    <ModeEditOutlinedIcon fontSize="medium" />
                  </IconButton>

                  <IconButtonDelete
                    isLoading={mutationDelete.isPending}
                    title="Reservations - Stay Card - Notes"
                    content="Are sure you want to delete this note ?"
                    onProceed={() => {
                      mutationDelete.mutate(
                        { id: item.id },
                        {
                          onSuccess: () => {
                            refetch();
                          },
                        }
                      );
                    }}
                    onCancel={() => console.log("onCancel")}
                  />
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {item.children.length > 0 ? (
                <SubCategoriesListing
                  data={item.children}
                  sx={{ pl: 3, pb: 2 }}
                  level={2}
                />
              ) : (
                <Box sx={{ pl: 3 }}>
                  <EmptyList />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <RootDialog
        open={dialogState.open}
        maxWidth={"md"}
        title={
          dialogState.defaultValues.id
            ? `Edit : ${dialogState.defaultValues.name}`
            : `NEW GATEGORY`
        }
        onClose={() =>
          setDialogState((dialogState) => ({ ...dialogState, open: false }))
        }
      >
        <Box sx={{ width: 350 }}>
          <CategoryForm
            defaultValues={dialogState.defaultValues}
            onSuccess={() => {
              refetch();
              setDialogState((dialogState) => ({
                ...dialogState,
                open: false,
              }));
            }}
          />
        </Box>
      </RootDialog>
    </Stack>
  );
};
