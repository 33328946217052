import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Form } from "./components/Form";

export const LogintPage: FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ width: 450, p: 3 }}>
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"stretch"}
          spacing={2}
        >
          <Typography fontWeight={"fontWeightMedium"}>LOGIN</Typography>
          <Form />
        </Stack>
      </Paper>
    </Box>
  );
};
