import CloseIcon from "@mui/icons-material/Close";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type RootDialogsPropsModel = Omit<DialogProps, "onClose"> & {
  onClose: VoidFunction;
  children: React.ReactNode;
  title: string | React.ReactNode;
};

const RootDialog: React.FC<RootDialogsPropsModel> = ({
  onClose,
  open,
  children,
  title,
  ...others
}) => {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...others}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 1.5,
          fontSize: 14,
          backgroundColor: (theme) => theme.palette.grey[200],
        }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 2,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </StyledDialog>
  );
};

export default RootDialog;
