import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { IconButton, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { deleteQuestionApi } from "../../../../Api/question.api";
import { useQuestionContext } from "../../context";
import { IconButtonDelete } from "../DeleteButton";
import { defaultFormValues } from "../Form/defaultFormValues";
import { StyledTableCell, StyledTableRow } from "../Styled";

export const DataTable: React.FC = () => {
  const { data, refetch, categories, setDialogState } = useQuestionContext();

  /**
   * DEELETE CATEGORY MUTATION
   */
  const mutationDelete = useMutation({
    // mutationKey: ["DeleteQuestionApi"],
    mutationFn: (params: { id: number }) => {
      return deleteQuestionApi(params.id);
    },
    onSuccess: () => {
      refetch();
    },
  });
  /***********************************/

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell align="right">Calories</StyledTableCell>
            <StyledTableCell align="center">Coefficient</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((item, index) => {
            const source = categories[item.srouceCategoryId!]?.name;
            const parent =
              categories[item.srouceCategoryId!].children[
                item.parentCategoryId!
              ]?.name;
            const category =
              categories[item.srouceCategoryId!].children[
                item.parentCategoryId!
              ].children[item.categoryId!]?.name;

            const path = `${source} - ${parent} - ${category}`;
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {item.title}
                </StyledTableCell>
                <StyledTableCell align="right">{path}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.coefficient}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"flex-end"}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setDialogState((dialogState) => ({
                          ...dialogState,
                          open: true,
                          defaultValues: {
                            ...defaultFormValues,
                            ...item,
                          },
                        }));
                      }}
                      size="small"
                      aria-label="delete"
                      color="primary"
                    >
                      <ModeEditOutlinedIcon fontSize="medium" />
                    </IconButton>

                    <IconButtonDelete
                      isLoading={mutationDelete.isPending}
                      title="Question"
                      content="Are sure you want to delete this question ?"
                      onProceed={() => {
                        mutationDelete.mutate({ id: item.id! });
                      }}
                      onCancel={() => console.log("onCancel")}
                    />
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
