import { createTheme } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: { light: "#5BE49B", main: "#00A76F", dark: "#007867" },
    secondary: { light: "#C684FF", main: "#8E33FF", dark: "#5119B7" },
    info: { light: "#61F3F3", main: "#00B8D9", dark: "#006C9C" },
    success: { light: "#77ED8B", main: "#22C55E", dark: "#118D57" },
    warning: { light: "#FFD666", main: "#FFAB00", dark: "#B76E00" },
    error: { light: "#FFAC82", main: "#FF5630", dark: "#B71D18" },
    grey: {
      "100": "#F9FAFB",
      "200": "#F4F6F8",
      "300": "#DFE3E8",
      "400": "#C4CDD5",
      "500": "#919EAB",
      "600": "#637381",
      "700": "#454F5B",
      "800": "#212B36",
      "900": "#161C24",
    },
    divider: "#DFE3E8",
    text: {
      primary: "#454F5B",
      secondary: "#637381",
    },

    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  shape: { borderRadius: 1 },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    h1: {
      fontSize: 64, //lineHeight: 80,
      fontWeight: 800,
      letterSpacing: 0,
    },
    h2: {
      fontSize: 48, //lineHeight: 64,
      fontWeight: 800,
      letterSpacing: 0,
    },
    h3: {
      fontSize: 32, // lineHeight: 48,
      fontWeight: 700,
      letterSpacing: 0,
    },
    h4: {
      fontSize: 24, // lineHeight: 36,
      fontWeight: 700,
      letterSpacing: 0,
    },
    h5: {
      fontSize: 20, // lineHeight: 30,
      fontWeight: 700,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: 16,
      // lineHeight: 24,
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontSize: 14,
      // lineHeight: 22,
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontSize: 16,
      // lineHeight: 24,
      fontWeight: 400,
      letterSpacing: 0,
    },
    body2: {
      fontSize: 14,
      // lineHeight: 22,
      fontWeight: 400,
      letterSpacing: 0,
    },
    caption: {
      fontSize: 12,
      // lineHeight: 18,
      fontWeight: 700,
      letterSpacing: 0,
    },
    button: {
      fontSize: 14,
      // lineHeight: 24,
      fontWeight: 700,
      letterSpacing: 0,
    },
  },
  shadows: [
    "none",
    "rgba(145, 158, 171, 0.2) 0px 2px 1px -1px, rgba(145, 158, 171, 0.14) 0px 1px 1px 0px, rgba(145, 158, 171, 0.12) 0px 1px 3px 0px",
    "rgba(145, 158, 171, 0.2) 0px 3px 1px -2px, rgba(145, 158, 171, 0.14) 0px 2px 2px 0px, rgba(145, 158, 171, 0.12) 0px 1px 5px 0px",
    "rgba(145, 158, 171, 0.2) 0px 3px 3px -2px, rgba(145, 158, 171, 0.14) 0px 3px 4px 0px, rgba(145, 158, 171, 0.12) 0px 1px 8px 0px",
    "rgba(145, 158, 171, 0.2) 0px 2px 4px -1px, rgba(145, 158, 171, 0.14) 0px 4px 5px 0px, rgba(145, 158, 171, 0.12) 0px 1px 10px 0px",
    "rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px",
    "rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 6px 10px 0px, rgba(145, 158, 171, 0.12) 0px 1px 18px 0px",
    "rgba(145, 158, 171, 0.2) 0px 4px 5px -2px, rgba(145, 158, 171, 0.14) 0px 7px 10px 1px, rgba(145, 158, 171, 0.12) 0px 2px 16px 1px",
    "rgba(145, 158, 171, 0.2) 0px 5px 5px -3px, rgba(145, 158, 171, 0.14) 0px 8px 10px 1px, rgba(145, 158, 171, 0.12) 0px 3px 14px 2px",
    "rgba(145, 158, 171, 0.2) 0px 5px 6px -3px, rgba(145, 158, 171, 0.14) 0px 9px 12px 1px, rgba(145, 158, 171, 0.12) 0px 3px 16px 2px",
    "rgba(145, 158, 171, 0.2) 0px 6px 6px -3px, rgba(145, 158, 171, 0.14) 0px 10px 14px 1px, rgba(145, 158, 171, 0.12) 0px 4px 18px 3px",
    "rgba(145, 158, 171, 0.2) 0px 6px 7px -4px, rgba(145, 158, 171, 0.14) 0px 11px 15px 1px, rgba(145, 158, 171, 0.12) 0px 4px 20px 3px",
    "rgba(145, 158, 171, 0.2) 0px 7px 8px -4px, rgba(145, 158, 171, 0.14) 0px 12px 17px 2px, rgba(145, 158, 171, 0.12) 0px 5px 22px 4px",
    "rgba(145, 158, 171, 0.2) 0px 7px 8px -4px, rgba(145, 158, 171, 0.14) 0px 13px 19px 2px, rgba(145, 158, 171, 0.12) 0px 5px 24px 4px",
    "rgba(145, 158, 171, 0.2) 0px 7px 9px -4px, rgba(145, 158, 171, 0.14) 0px 14px 21px 2px, rgba(145, 158, 171, 0.12) 0px 5px 26px 4px",
    "rgba(145, 158, 171, 0.2) 0px 8px 9px -5px, rgba(145, 158, 171, 0.14) 0px 15px 22px 2px, rgba(145, 158, 171, 0.12) 0px 6px 28px 5px",
    "rgba(145, 158, 171, 0.2) 0px 8px 10px -5px, rgba(145, 158, 171, 0.14) 0px 16px 24px 2px, rgba(145, 158, 171, 0.12) 0px 6px 30px 5px",
    "rgba(145, 158, 171, 0.2) 0px 8px 11px -5px, rgba(145, 158, 171, 0.14) 0px 17px 26px 2px, rgba(145, 158, 171, 0.12) 0px 6px 32px 5px",
    "rgba(145, 158, 171, 0.2) 0px 9px 11px -5px, rgba(145, 158, 171, 0.14) 0px 18px 28px 2px, rgba(145, 158, 171, 0.12) 0px 7px 34px 6px",
    "rgba(145, 158, 171, 0.2) 0px 9px 12px -6px, rgba(145, 158, 171, 0.14) 0px 19px 29px 2px, rgba(145, 158, 171, 0.12) 0px 7px 36px 6px",
    "rgba(145, 158, 171, 0.2) 0px 10px 13px -6px, rgba(145, 158, 171, 0.14) 0px 20px 31px 3px, rgba(145, 158, 171, 0.12) 0px 8px 38px 7px",
    "rgba(145, 158, 171, 0.2) 0px 10px 13px -6px, rgba(145, 158, 171, 0.14) 0px 21px 33px 3px, rgba(145, 158, 171, 0.12) 0px 8px 40px 7px",
    "rgba(145, 158, 171, 0.2) 0px 10px 14px -6px, rgba(145, 158, 171, 0.14) 0px 22px 35px 3px, rgba(145, 158, 171, 0.12) 0px 8px 42px 7px",
    "rgba(145, 158, 171, 0.2) 0px 11px 14px -7px, rgba(145, 158, 171, 0.14) 0px 23px 36px 3px, rgba(145, 158, 171, 0.12) 0px 9px 44px 8px",
    "rgba(145, 158, 171, 0.2) 0px 11px 15px -7px, rgba(145, 158, 171, 0.14) 0px 24px 38px 3px, rgba(145, 158, 171, 0.12) 0px 9px 46px 8px",
  ],
};

export const theme = createTheme(themeOptions);
