import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  createCategoryApi,
  updateCategoryApi,
} from "../../../../../../Api/category.api";
import { CategoryFormModel } from "../../../../models";
import { schema } from "./schema";
import _ from "lodash";
import { ImageField } from "../../../../../../components/ImageField";

interface CategoryFormPropsModel {
  defaultValues: CategoryFormModel;
  onSuccess: VoidFunction;
}

export const CategoryForm: FC<CategoryFormPropsModel> = ({
  defaultValues,
  onSuccess,
}) => {
  console.log({ defaultValues });
  /**
   * FORM HOOK
   */
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CategoryFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  /*********************************************/

  /**
   * CREATE CATEGORY MUTATION
   */
  const mutationCreate = useMutation({
    mutationKey: ["AddCategorypi"],
    mutationFn: (data: CategoryFormModel) => {
      return createCategoryApi(data);
    },
  });
  /***********************************/

  /**
   * UPDATE CATEGORY MUTATION
   */
  const mutationUpdate = useMutation({
    mutationKey: ["AddCategorypi"],
    mutationFn: (data: CategoryFormModel) => {
      return updateCategoryApi(_.omit(data, ["parentCategoryId"]));
    },
  });
  /***********************************/

  /**
   * HANDLE SUBMIT FROM
   */
  const onSubmit = (data: CategoryFormModel) => {
    if (data.id) {
      mutationUpdate.mutate(data, {
        onSuccess: () => {
          onSuccess();
        },
      });
    } else {
      mutationCreate.mutate(data, {
        onSuccess: () => {
          onSuccess();
        },
      });
    }
  };
  /*********************************************/

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <DialogContent dividers>
        <Box sx={{ p: 2 }}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                error={Boolean(errors.name)}
                fullWidth
                label="Category Name"
                variant="outlined"
              />
            )}
          />
        </Box>

        <Box sx={{ p: 2 }}>
          <Controller
            control={control}
            name="requiredPoints"
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                error={Boolean(errors.requiredPoints)}
                fullWidth
                label="Required Points"
                variant="outlined"
              />
            )}
          />
        </Box>

        <Box sx={{ p: 3, py: 1 }}>
          <Controller
            control={control}
            name="icon"
            render={({ field }) => (
              <ImageField
                value={field.value ?? null}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mutationCreate.isPending || mutationUpdate.isPending}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
        >
          SAVE
        </Button>
      </DialogActions>
    </form>
  );
};
