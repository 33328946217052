import { CATEGORIES_ENDPOINT } from "../constants/endpoint";
import httpClient from "../Lib/http-client";
import { CategoryModel } from "../models/category.model";
import { CategoryFormModel } from "../Pages/CategoryPage/models";

export const fetchCategoriesApi = (): Promise<CategoryModel[]> => {
  return httpClient.get(CATEGORIES_ENDPOINT);
};

export const createCategoryApi = (
  body: CategoryFormModel
): Promise<CategoryModel> => {
  return httpClient.post(CATEGORIES_ENDPOINT, body);
};

export const updateCategoryApi = (
  body: CategoryFormModel
): Promise<CategoryModel> => {
  return httpClient.put(`${CATEGORIES_ENDPOINT}/${body.id}`, body);
};

export const deleteCategoryApi = (id?: number): Promise<void> => {
  return httpClient.delete(`${CATEGORIES_ENDPOINT}/${id}`);
};
