import { Container } from "@mui/material";
import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PROTECTED_PATH } from "../../constants/path";
import { useAuthContext } from "../AuthProvider";

export const PublicLayout: FC = () => {
  const auth = useAuthContext();
  const location = useLocation();

  if (auth.user) {
    return <Navigate to={PROTECTED_PATH} state={{ from: location }} replace />;
  }

  return (
    <Container maxWidth="xl" disableGutters sx={{ height: "100vh" }}>
      <Outlet />
    </Container>
  );
};
