import { FC } from "react";
import { Listing } from "./components/Listing";
import CategoryProvider from "./context";

export const CategoryPage: FC = () => {
  return (
    <CategoryProvider>
      <Listing />
    </CategoryProvider>
  );
};
