import { Box, CircularProgress } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, ReactNode, createContext, useContext } from "react";
import { signinApi, whoamiApi } from "../../Api/signin.api";
import { ACCESS_TOKEN } from "../../constants/common";
import { SigninModel } from "../../models/signin.model";
import { UserModel } from "../../models/user.model";

interface AuthContextModel {
  user: UserModel | undefined;
  isPending: boolean;

  isError: boolean;
  signin: (data: SigninModel, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextModel>(null!);
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  /**
   * SIGNIN MUTATION
   */
  const mutation = useMutation({
    mutationKey: ["signinApi"],
    mutationFn: (data: SigninModel) => {
      return signinApi(data);
    },
  });
  /***********************************/

  const {
    isLoading,
    data: user,
    refetch,
  } = useQuery({
    queryKey: ["whoamiApi-query"],
    queryFn: () => whoamiApi(),
    enabled: true,
  });

  /***********************************/
  const signin = (data: SigninModel, callback: VoidFunction) => {
    mutation.mutate(data, {
      onSuccess: (data) => {
        localStorage.setItem(ACCESS_TOKEN, data.access_token);
        refetch();
        callback();
      },
    });
  };
  /***********************************/

  const signout = (callback: VoidFunction) => {
    localStorage.removeItem(ACCESS_TOKEN);
    callback();
  };
  /***********************************/

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signin,
        signout,
        isPending: mutation.isPending,
        isError: mutation.isError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
