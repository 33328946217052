import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  createQuestionApi,
  updateQuestionApi,
} from "../../../../Api/question.api";
import { ImageField } from "../../../../components/ImageField";
import { useQuestionContext } from "../../context";
import { QuestionFormModel } from "../../models";
import { schema } from "./schema";
import { SuggestionsForm } from "./suggestionsForm";

interface QuestionFormPropsModel {
  defaultValues: QuestionFormModel;
  onSuccess: VoidFunction;
}

export const QuestionForm: FC<QuestionFormPropsModel> = ({
  defaultValues,
  onSuccess,
}) => {
  const { categories } = useQuestionContext();

  /**
   * FORM HOOK
   */
  const methods = useForm<QuestionFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitted },
  } = methods;

  const formValues = watch();

  console.log({ formValues, errors });

  /*********************************************/

  /**
   * CREATE CATEGORY MUTATION
   */
  const mutationCreate = useMutation({
    mutationKey: ["AddQuestionpi"],
    mutationFn: (data: QuestionFormModel) => {
      return createQuestionApi(data);
    },
  });
  /***********************************/

  /**
   * UPDATE CATEGORY MUTATION
   */
  const mutationUpdate = useMutation({
    mutationKey: ["AddQuestionpi"],
    mutationFn: (data: QuestionFormModel) => {
      return updateQuestionApi(data);
    },
  });
  /***********************************/

  /**
   * HANDLE SUBMIT FROM
   */
  const onSubmit = (data: QuestionFormModel) => {
    console.log({ data });
    if (data.id) {
      mutationUpdate.mutate(data, {
        onSuccess: () => {
          onSuccess();
        },
      });
    } else {
      mutationCreate.mutate(data, {
        onSuccess: () => {
          onSuccess();
        },
      });
    }
  };
  /*********************************************/

  const parentCategoryItems = useMemo(() => {
    if (!formValues.srouceCategoryId) return [];
    return Object.values(
      categories[formValues.srouceCategoryId]?.children || {}
    );
  }, [categories, formValues.srouceCategoryId]);

  const categoryItems = useMemo(() => {
    if (
      !Boolean(formValues.srouceCategoryId) ||
      !Boolean(formValues.parentCategoryId)
    )
      return [];

    return Object.values(
      (categories[formValues.srouceCategoryId!]?.children || {})[
        formValues.parentCategoryId!
      ]?.children ?? {}
    );
  }, [categories, formValues.srouceCategoryId, formValues.parentCategoryId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <DialogContent dividers>
          <Stack direction={"row"}>
            <Box sx={{ width: 500 }}>
              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  name="withSuggestions"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.checked === false) {
                              setValue("suggestions", []);
                            }
                            if (isSubmitted) {
                              trigger();
                            }

                            field.onChange(event.target.checked);
                          }}
                        />
                      }
                      label="with suggestions"
                    />
                  )}
                />
              </Box>
              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  name="srouceCategoryId"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={Boolean(errors.srouceCategoryId)}
                    >
                      <InputLabel
                        size="small"
                        id="source-select"
                        sx={{
                          width: "auto",
                          backgroundColor: "#fff",
                          px: 1,
                        }}
                      >
                        Srouce
                      </InputLabel>
                      <Select
                        labelId="source-select"
                        id="demo-simple-select-helper"
                        {...field}
                        size="small"
                        value={field.value ?? null}
                        onChange={(event: SelectChangeEvent<number | null>) => {
                          field.onChange(event.target.value ?? null!);
                          setValue("parentCategoryId", null!);
                          setValue("categoryId", null!);
                        }}
                      >
                        <MenuItem value={""}> None</MenuItem>
                        {(Object.values(categories || {}) || []).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box sx={{ p: 3, py: 1 }}>
                <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
                  <Controller
                    control={control}
                    name="parentCategoryId"
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        error={Boolean(errors.parentCategoryId)}
                      >
                        <InputLabel
                          size="small"
                          id="parent-select"
                          sx={{
                            width: "auto",
                            backgroundColor: "#fff",
                            px: 1,
                          }}
                        >
                          Parent
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="parent-select"
                          id="demo-simple-select-helper"
                          value={field.value ?? null}
                          onChange={(
                            event: SelectChangeEvent<number | null>
                          ) => {
                            field.onChange(Number(event.target.value) ?? null);
                            setValue("categoryId", null!);
                          }}
                        >
                          <MenuItem value={null!}>None</MenuItem>
                          {(parentCategoryItems || []).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    control={control}
                    name="categoryId"
                    render={({ field }) => (
                      <FormControl fullWidth error={Boolean(errors.categoryId)}>
                        <InputLabel
                          size="small"
                          id="category-select"
                          sx={{
                            width: "auto",
                            backgroundColor: "#fff",
                            px: 1,
                          }}
                        >
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="Age"
                          size="small"
                          value={field.value ?? null}
                          onChange={(
                            event: SelectChangeEvent<number | null>
                          ) => {
                            field.onChange(event.target.value ?? undefined);
                          }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {(Object.values(categoryItems || {}) || []).map(
                            (source) => (
                              <MenuItem key={source.id} value={source.id}>
                                {source.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Box>

              <Box sx={{ p: 3, py: 1 }}>
                <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
                  <Controller
                    control={control}
                    name="coefficient"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        type="number"
                        value={field.value ?? ""}
                        error={Boolean(errors.coefficient)}
                        fullWidth
                        label="Coefficient"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        type="number"
                        value={field.value ?? ""}
                        error={Boolean(errors.duration)}
                        fullWidth
                        label="Duration"
                        variant="outlined"
                      />
                    )}
                  />
                </Stack>
              </Box>
              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value ?? ""}
                      error={Boolean(errors.title)}
                      fullWidth
                      label="Title"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="headContent"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value ?? ""}
                      error={Boolean(errors.headContent)}
                      fullWidth
                      label="Header"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="content"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      multiline
                      minRows={4}
                      value={field.value ?? ""}
                      error={Boolean(errors.content)}
                      fullWidth
                      label="Content"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      multiline
                      minRows={4}
                      value={field.value ?? ""}
                      error={Boolean(errors.description)}
                      fullWidth
                      label="Description"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="response"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value ?? ""}
                      error={Boolean(errors.response)}
                      fullWidth
                      label="Response"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <Box sx={{ p: 3, py: 1 }}>
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <ImageField
                      value={field.value ?? null}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </Box>
            </Box>

            {formValues.withSuggestions && (
              <Box sx={{ p: 3, py: 1, flexGrow: 1 }}>
                <Stack direction={"column"}>
                  <Box sx={{ p: 3, py: 1 }}>
                    <Typography>Suggestions</Typography>
                    {errors.suggestions &&
                      errors.suggestions.type === "min" && (
                        <Typography sx={{ color: "red", fontSize: 12 }}>
                          Min length = 1
                        </Typography>
                      )}
                  </Box>
                  <Box sx={{ p: 3, py: 1.5 }}>
                    <SuggestionsForm />
                  </Box>
                </Stack>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutationCreate.isPending || mutationUpdate.isPending}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            SAVE
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};
