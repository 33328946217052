import { useQuery } from "@tanstack/react-query";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import { SkeletonListing } from "../../../components/SkeletonListing";
import {
  QuestionFormFilterModel,
  QuestionFormModel,
  QuestionModel,
} from "../models";
import { fetchQuestionsApi } from "../../../Api/question.api";
import { fetchCategoriesApi } from "../../../Api/category.api";
import { CategoryModel } from "../../../models/category.model";
import { arrayToObject } from "../../../utils/ArrayToObject";
import RootDialog from "../../../components/RootDialog";
import { Box } from "@mui/material";
import { QuestionForm } from "../components/Form";
import { defaultFormValues } from "../components/Form/defaultFormValues";

interface QuestionContextModel {
  refetch: VoidFunction;
  data: QuestionModel[];
  categories: Record<number | string, CategoryModel>;
  setDialogState: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      defaultValues: QuestionFormModel;
    }>
  >;
  dialogState: {
    open: boolean;
    defaultValues: QuestionFormModel;
  };
  setFilter: (params: QuestionFormFilterModel) => void;
  filterParams: QuestionFormFilterModel;
}

const QuestionContext = createContext<QuestionContextModel>(null!);
export const useQuestionContext = () => useContext(QuestionContext);

const QuestionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filterState, setFilterState] = useState<QuestionFormFilterModel>({
    srouceCategoryId: null,
    parentCategoryId: null,
    categoryId: null,
  });

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    defaultValues: QuestionFormModel;
  }>({
    open: false,
    defaultValues: defaultFormValues,
  });

  // Queries
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["queestions-query", ...Object.values(filterState)],
    queryFn: () => fetchQuestionsApi(filterState),
  });

  // Queries
  const { isLoading: categoriesIsLoading, data: categories } = useQuery({
    queryKey: ["categories-query"],
    queryFn: () => fetchCategoriesApi(),
  });

  const categoriesObj = useMemo(() => {
    return arrayToObject(categories);
  }, [categories]);

  if (isLoading || categoriesIsLoading) {
    return <SkeletonListing />;
  }

  return (
    <QuestionContext.Provider
      value={{
        setFilter: (params: QuestionFormFilterModel) => {
          setFilterState(params);
        },
        refetch: () => {
          refetch();
        },
        filterParams: filterState,
        data: data ?? [],
        categories: categoriesObj,
        setDialogState,
        dialogState,
      }}
    >
      {children}
      <RootDialog
        open={dialogState.open}
        fullScreen
        maxWidth={"xl"}
        title={
          dialogState.defaultValues.id
            ? `Edit : ${dialogState.defaultValues.title}`
            : `NEW GATEGORY`
        }
        onClose={() =>
          setDialogState((dialogState) => ({ ...dialogState, open: false }))
        }
      >
        <Box sx={{ width: "100%" }}>
          <QuestionForm
            defaultValues={dialogState.defaultValues}
            onSuccess={() => {
              refetch();
              setDialogState((dialogState) => ({
                ...dialogState,
                open: false,
              }));
            }}
          />
        </Box>
      </RootDialog>
    </QuestionContext.Provider>
  );
};

export default QuestionProvider;
