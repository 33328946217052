export const arrayToObject = <
  T extends { id?: number | string; children?: Array<T> }
>(
  data: T[] | undefined
): Record<number | string, T> =>
  (data || []).reduce((acc, cur) => {
    const { id } = cur;
    return {
      ...acc,
      [id!]: {
        ...cur,
        ...(cur?.children ? { children: arrayToObject(cur.children) } : {}),
      },
    };
  }, {});
