import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";

import { FC, useState } from "react";
import RootDialog from "../RootDialog";

interface ConfirmationDilogPropsModel {
  isLoading: boolean;
  isOpen: boolean;
  title: string;
  content: string;
  onProceed: (callback?: VoidFunction) => void;
  onCancel: (callback?: VoidFunction) => void;
}

export const ConfirmationDialog: FC<ConfirmationDilogPropsModel> = ({
  isOpen,
  title,
  content,
  onProceed,
  onCancel,
}) => {
  return (
    <>
      {isOpen && (
        <RootDialog
          maxWidth={"xl"}
          onClose={() => {
            onCancel();
          }}
          open={isOpen}
          title={title}
        >
          <DialogContent sx={{ pl: 0, pr: 0 }}>
            <DialogContentText>{content}</DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={{ mr: "0.5rem" }}
              onClick={() => onCancel()}
            >
              <Typography fontWeight={600}>CANCEL</Typography>
            </Button>

            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => {
                onProceed();
              }}
            >
              <Typography fontWeight={600}>CONFIRM</Typography>
            </Button>
          </DialogActions>
        </RootDialog>
      )}
    </>
  );
};

/**
 *
 */

export function withConfirmationDilog<P extends object>(
  Component: React.ComponentType<P>
) {
  const ConfirmationAction = ({
    isLoading = false,
    title = "",
    content = "",
    onProceed = () => console.log("onProceed"),
    onCancel = () => console.log("onCancel"),
    ...props
  }: Omit<ConfirmationDilogPropsModel, "isOpen"> & P) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
      <>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <Component {...(props as P)} />
        </Box>
        <ConfirmationDialog
          isLoading={isLoading}
          isOpen={open}
          title={title}
          content={content}
          onProceed={() => {
            setOpen(false);
            onProceed();
          }}
          onCancel={() => {
            setOpen(false);
            onCancel();
          }}
        />
      </>
    );
  };

  ConfirmationAction.displayName = `WithConfirmationDilog`;

  return ConfirmationAction;
}
