import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "../../Pages/HomePage";
import { LogintPage } from "../../Pages/LoginPage";
import {
  CATEGORY_PATH,
  PROTECTED_PATH,
  PUBLIC_PATH,
  QUESTION_PATH,
} from "../../constants/path";
import { PrivateLayout } from "../Private";
import { PublicLayout } from "../Public";
import { CategoryPage } from "../../Pages/CategoryPage";
import { QuestionPage } from "../../Pages/QuestionPage";

export const router = createBrowserRouter([
  {
    path: PROTECTED_PATH,
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: CATEGORY_PATH,
        element: <CategoryPage />,
      },

      {
        path: QUESTION_PATH,
        element: <QuestionPage />,
      },
    ],
  },
  {
    path: PUBLIC_PATH,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <LogintPage />,
      },
    ],
  },
]);
