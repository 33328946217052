import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Stack, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../Layouts/AuthProvider";
import { PROTECTED_PATH } from "../../../../constants/path";
import { SigninModel } from "../../models/signin.model";
import { schema } from "./schema";

export const Form: FC = () => {
  const { signin, isPending, isError } = useAuthContext();
  /**
   * REACT-ROUTER-DOM
   */
  const navigate = useNavigate();
  /*********************************************/

  /**
   * FORM HOOK
   */
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SigninModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  /*********************************************/

  /**
   * HANDLE SUBMIT FROM
   */
  const onSubmit = (data: SigninModel) => {
    signin(data, () => {
      navigate(PROTECTED_PATH);
    });
  };
  /*********************************************/

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Stack direction={"column"} alignItems={"center"} spacing={3}>
        {!isPending && isError && (
          <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
            Unable to connect.
          </Alert>
        )}

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              error={Boolean(errors.email)}
              fullWidth
              label="E-mail"
              variant="outlined"
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value ?? ""}
              error={Boolean(errors.password)}
              fullWidth
              label="Password"
              variant="outlined"
            />
          )}
        />

        <Button
          disabled={isPending}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
        >
          LOGIN
        </Button>
      </Stack>
    </form>
  );
};
