import * as yup from "yup";
export const schema = yup
  .object({
    id: yup.mixed().nullable(),
    parentCategoryId: yup.mixed().nullable(),
    name: yup.string().required(),
    requiredPoints: yup.mixed().nullable(),
    icon: yup.string().nullable(),
  })
  .required();
