import {
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { FC, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { useQuestionContext } from "../../context";
import { QuestionFormFilterModel } from "../../models";

interface QuestionFormFilterPropsModel {
  onSubmit: (data: QuestionFormFilterModel) => void;
  defaultValues: QuestionFormFilterModel;
}

export const QuestionFormFilter: FC<QuestionFormFilterPropsModel> = (props) => {
  const { categories } = useQuestionContext();

  /**
   * FORM HOOK
   */
  const methods = useForm<QuestionFormFilterModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: props.defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = methods;

  const formValues = watch();

  console.log({ formValues, errors });

  /*********************************************/

  /**
   * HANDLE SUBMIT FROM
   */
  const onSubmit = (data: QuestionFormFilterModel) => {
    props.onSubmit(data);
  };
  /*********************************************/

  const parentCategoryItems = useMemo(() => {
    if (!formValues.srouceCategoryId) return [];
    return Object.values(
      categories[formValues.srouceCategoryId]?.children || {}
    );
  }, [categories, formValues.srouceCategoryId]);

  const categoryItems = useMemo(() => {
    if (
      !Boolean(formValues.srouceCategoryId) ||
      !Boolean(formValues.parentCategoryId)
    )
      return [];

    return Object.values(
      (categories[formValues.srouceCategoryId!]?.children || {})[
        formValues.parentCategoryId!
      ]?.children ?? {}
    );
  }, [categories, formValues.srouceCategoryId, formValues.parentCategoryId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
          <Controller
            name="srouceCategoryId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={Boolean(errors.srouceCategoryId)}>
                <InputLabel
                  size="small"
                  id="source-select"
                  sx={{
                    width: "auto",
                    backgroundColor: "#fff",
                    px: 1,
                  }}
                >
                  Srouce
                </InputLabel>
                <Select
                  labelId="source-select"
                  id="demo-simple-select-helper"
                  {...field}
                  size="small"
                  value={field.value ?? null}
                  onChange={(event: SelectChangeEvent<number | null>) => {
                    field.onChange(event.target.value ?? null!);
                    setValue("parentCategoryId", null!);
                    setValue("categoryId", null!);
                  }}
                >
                  <MenuItem value={""}> None</MenuItem>
                  {(Object.values(categories || {}) || []).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="parentCategoryId"
            render={({ field }) => (
              <FormControl fullWidth error={Boolean(errors.parentCategoryId)}>
                <InputLabel
                  size="small"
                  id="parent-select"
                  sx={{
                    width: "auto",
                    backgroundColor: "#fff",
                    px: 1,
                  }}
                >
                  Parent
                </InputLabel>
                <Select
                  size="small"
                  labelId="parent-select"
                  id="demo-simple-select-helper"
                  value={field.value ?? null}
                  onChange={(event: SelectChangeEvent<number | null>) => {
                    field.onChange(Number(event.target.value) ?? null);
                    setValue("categoryId", null!);
                  }}
                >
                  <MenuItem value={null!}>None</MenuItem>
                  {(parentCategoryItems || []).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="categoryId"
            render={({ field }) => (
              <FormControl fullWidth error={Boolean(errors.categoryId)}>
                <InputLabel
                  size="small"
                  id="category-select"
                  sx={{
                    width: "auto",
                    backgroundColor: "#fff",
                    px: 1,
                  }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Age"
                  size="small"
                  value={field.value ?? null}
                  onChange={(event: SelectChangeEvent<number | null>) => {
                    field.onChange(event.target.value ?? undefined);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {(Object.values(categoryItems || {}) || []).map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: 200 }}
          >
            SEARCH
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};
