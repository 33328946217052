import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FC, useState } from "react";
import { SubCategoriesListing } from "../..";
import { deleteCategoryApi } from "../../../../../../../../Api/category.api";
import RootDialog from "../../../../../../../../components/RootDialog";
import { CategoryModel } from "../../../../../../../../models/category.model";
import { useCategoryContext } from "../../../../../../context";
import { CategoryFormModel } from "../../../../../../models";
import { CategoryForm } from "../../../CategoryForm";
import { IconButtonDelete } from "../../../DeleteButton";
import { EmptyList } from "../../../EmptyList";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const categoryFormValue: CategoryFormModel = {
  name: "",
  parentCategoryId: null,
};

export const SubCategoriesListItem: FC<{
  item: CategoryModel;
  level: number;
  index: number;
}> = ({ item, level, index }) => {
  const { refetch } = useCategoryContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    defaultValues: CategoryFormModel;
  }>({
    open: false,
    defaultValues: categoryFormValue,
  });

  /**
   * DEELETE CATEGORY MUTATION
   */
  const mutationDelete = useMutation({
    mutationKey: ["DeleteCategorypi", item.id],
    mutationFn: () => {
      return deleteCategoryApi(item.id);
    },
    onSuccess: () => {
      refetch();
    },
  });
  /***********************************/

  return (
    <>
      <ListItem
        id={`id-${item.id}`}
        onClick={() => {
          if (level <= 2 && item.children.length > 0) {
            setExpanded(!expanded);
          }
        }}
        secondaryAction={
          <Stack direction={"row"} spacing={1}>
            {level <= 2 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pr: 3,
                }}
              >
                <Typography
                  fontWeight={400}
                  sx={{
                    color: (theme) => theme.palette.grey[400],
                  }}
                >{`${item.children.length} Items`}</Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
              </Box>
            )}

            {level <= 2 && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDialogState((dialogState) => ({
                    ...dialogState,
                    open: true,
                    defaultValues: {
                      ...categoryFormValue,
                      parentCategoryId: item.id,
                    },
                  }));
                }}
                size="small"
                aria-label="delete"
                color="secondary"
              >
                <AddOutlinedIcon fontSize="medium" />
              </IconButton>
            )}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setDialogState((dialogState) => ({
                  ...dialogState,
                  open: true,
                  defaultValues: {
                    ...categoryFormValue,
                    name: item.name,
                    id: item.id,
                    parentCategoryId: item.id,
                    icon: item.icon,
                  },
                }));
              }}
              size="small"
              aria-label="delete"
              color="primary"
            >
              <ModeEditOutlinedIcon fontSize="medium" />
            </IconButton>

            <IconButtonDelete
              isLoading={mutationDelete.isPending}
              title="Reservations - Stay Card - Notes"
              content="Are sure you want to delete this note ?"
              onProceed={() => {
                mutationDelete.mutate();
              }}
              onCancel={() => console.log("onCancel")}
            />
          </Stack>
        }
        disablePadding
      >
        <ListItemButton role={undefined} dense>
          <ListItemIcon sx={{ minWidth: 30 }}>
            {level <= 2 ? (
              <KeyboardArrowRightIcon />
            ) : (
              <CircleOutlinedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: (theme) => theme.palette.grey[600],
            }}
            primary={
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography>{`${item.name}`}</Typography>
                <Typography
                  sx={{ fontSize: 11 }}
                >{`Required Points : ${item.requiredPoints}`}</Typography>
                <Typography
                  sx={{ fontSize: 11 }}
                >{`Questions Points : ${item.sumCoefficient}`}</Typography>
              </Stack>
            }
          />
        </ListItemButton>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {item.children.length > 0 ? (
          <SubCategoriesListing
            data={item.children}
            sx={{ pl: 6 }}
            level={level + 1}
          />
        ) : (
          <Box sx={{ pl: 6 }}>
            <EmptyList />
          </Box>
        )}
      </Collapse>

      <RootDialog
        open={dialogState.open}
        maxWidth={"md"}
        title={
          dialogState.defaultValues.id
            ? `Edit : ${dialogState.defaultValues.name}`
            : `NEW GATEGORY`
        }
        onClose={() =>
          setDialogState((dialogState) => ({ ...dialogState, open: false }))
        }
      >
        <Box sx={{ width: 350 }}>
          <CategoryForm
            defaultValues={dialogState.defaultValues}
            onSuccess={() => {
              refetch();
              setDialogState((dialogState) => ({
                ...dialogState,
                open: false,
              }));
            }}
          />
        </Box>
      </RootDialog>
    </>
  );
};
