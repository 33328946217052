import { Box, Button, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import RootDialog from "../../components/RootDialog";
import { DataTable } from "./components/DataTable";
import QuestionProvider, { useQuestionContext } from "./context";
import { QuestionForm } from "./components/Form";
import { defaultFormValues } from "./components/Form/defaultFormValues";
import { QuestionFormFilter } from "./components/FormFilter";

export const QuestionPage: FC = () => {
  return (
    <QuestionProvider>
      <PageContent />
    </QuestionProvider>
  );
};

const PageContent: FC = () => {
  const { data, refetch, setFilter, filterParams } = useQuestionContext();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    defaultValues: any;
  }>({
    open: false,
    defaultValues: {},
  });

  return (
    <Stack direction={"column"} spacing={3}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey[200],
          height: 56.5,
          borderRadius: 0.75,
          px: 1.5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
          <Box>
            <Typography
              fontWeight={700}
              sx={{
                color: (theme) => theme.palette.grey[700],
              }}
            >
              {data.length} items
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 0.5 }}
            onClick={() => {
              setDialogState((dialogState) => ({
                ...dialogState,
                open: true,
                defaultValues: defaultFormValues,
              }));
            }}
          >
            ADD NEW
          </Button>
        </Stack>
      </Box>

      <Box>
        <QuestionFormFilter
          defaultValues={filterParams}
          onSubmit={(dataFilter) => setFilter(dataFilter)}
        />
      </Box>
      <Box>
        <DataTable />
      </Box>
      <RootDialog
        open={dialogState.open}
        fullScreen
        maxWidth={"md"}
        title={
          dialogState.defaultValues.id
            ? `Edit : ${dialogState.defaultValues.name}`
            : `NEW QUESTION`
        }
        onClose={() =>
          setDialogState((dialogState) => ({ ...dialogState, open: false }))
        }
      >
        <Box sx={{ width: "100%" }}>
          <QuestionForm
            defaultValues={dialogState.defaultValues}
            onSuccess={() => {
              refetch();
              setDialogState((dialogState) => ({
                ...dialogState,
                open: false,
              }));
            }}
          />
        </Box>
      </RootDialog>
    </Stack>
  );
};
