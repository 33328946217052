import { useMutation } from "@tanstack/react-query";
import { FC, useCallback, useMemo } from "react";

import { useDropzone } from "react-dropzone";
import { uploadApi } from "../../Api/upload.api";
import { Box, IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const ImageField: FC<{
  onChange: (image: string | null) => void;
  value: string | null;
}> = ({ onChange, value }) => {
  /**
   * UPLOAD MUTATION
   */
  const mutationUpload = useMutation({
    mutationKey: ["UploadApi"],
    mutationFn: (data: { file: File }) => {
      return uploadApi(data.file);
    },
  });
  /***********************************/

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0] ?? null;
    if (acceptedFile) {
      mutationUpload.mutate(
        { file: acceptedFile },
        {
          onSuccess: (data) => {
            onChange(data);
          },
          onError: () => {
            onChange(null);
          },
        }
      );
    } else {
      onChange(null);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const previewPhoto = useMemo(() => {
    return typeof value === "string" && Boolean(value)
      ? `${process.env.REACT_APP_ASSETS_URL}${value}`
      : null;
  }, [value]);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        sx={{
          width: "100%",
          height: 40,
          border: "dashed 1px #ddd",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#eee",

          ...(previewPhoto
            ? {
                backgroundImage: `url("${previewPhoto}")`,
                backgroundSize: "cover",
                height: 100,
                width: 100,
              }
            : {}),
        }}
      >
        {previewPhoto ? (
          <IconButton
            aria-label="delete"
            color="error"
            sx={{ position: "absolute", top: -20, right: -20 }}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        ) : (
          <Typography sx={{ fontSize: 12 }}>
            Drop some image here, or click to select image
          </Typography>
        )}
      </Box>{" "}
    </div>
  );
};
