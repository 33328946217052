import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

/**
 *
 * ACCORDION
 */
export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  border: `0px solid ${theme.palette.divider}`,

  "&.Mui-expanded.MuiPaper-root": {
    backgroundColor: theme.palette.background.paper,
    "&:first-of-type": {
      overflow: "hidden",
      borderRadius: theme.shape.borderRadius,
    },
    "&:last-of-type": {
      overflow: "hidden",
      borderRadius: theme.shape.borderRadius,
    },
  },

  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "&::before": {
    display: "none",
  },
}));
/***********************************************/

/**
 * ACCORDIONSUMMARY
 */
export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "&.MuiButtonBase-root.Mui-expanded": {
    backgroundColor: theme.palette.grey[200],
    borderRaduis: theme.shape.borderRadius,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
/***********************************************/

/**
 * ACCORDIONDETAILS
 */
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
  //borderLeft: `1px solid ${theme.palette.divider}`,
}));

/***********************************************/
