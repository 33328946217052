import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { fetchUsersStatApi } from "../../Api/stat.api";
import { SkeletonListing } from "../../components/SkeletonListing";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "./components/Styled";
export const HomePage: FC = () => {
  // Queries
  const { isLoading, data } = useQuery({
    queryKey: ["users-stat-query"],
    queryFn: () => fetchUsersStatApi(),
  });

  if (isLoading) {
    return <SkeletonListing />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>User</StyledTableCell>
            <StyledTableCell align="center">Total Questions</StyledTableCell>
            <StyledTableCell align="center">Total Points</StyledTableCell>
            <StyledTableCell align="center">Total Attempts</StyledTableCell>
            <StyledTableCell align="center">Total Seconds</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data || []).map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {item.user_name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.totalAnswers}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.totalPoints}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.totalAttempts}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.totalSeconds}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
