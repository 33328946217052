import { LOGIN_ENDPOINT, WHOAMI_ENDPOINT } from "../constants/endpoint";
import httpClient from "../Lib/http-client";
import { UserModel } from "../models/user.model";

export const signinApi = (requestBody: {
  email: string;
  password: string;
}): Promise<{ access_token: string }> => {
  return httpClient.post(LOGIN_ENDPOINT, requestBody);
};

export const whoamiApi = (): Promise<UserModel> => {
  return httpClient.get(WHOAMI_ENDPOINT);
};
