export const LOGIN_ENDPOINT = "auth/signin";
export const WHOAMI_ENDPOINT = "user/whoami";

export const CATEGORIES_ENDPOINT = "category";

export const QUESTIONS_ENDPOINT = "question";

export const UPLOAD_ENDPOINT = "upload";

export const USERS_STAT_ENDPOINT = "user-ansawer/topUsersPoints";
