import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DraftsIcon from "@mui/icons-material/Drafts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import StarBorder from "@mui/icons-material/StarBorder";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_PATH,
  PROTECTED_PATH,
  QUESTION_PATH,
} from "../../../../constants/path";
import { Box, Stack, Typography } from "@mui/material";

const RightMenu: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Stack
      direction={"column"}
      sx={{ borderRight: "dashed 1px #ddd", height: "100%" }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 300,
          height: 152,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1">I9RA2</Typography>
      </Box>
      <Box>
        <List
          dense
          sx={{
            width: "100%",
            maxWidth: 300,
            bgcolor: "background.paper",
            height: "100%",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{
                height: 60,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Typography fontSize={14} fontWeight={600}>
                MANAGEMENT
              </Typography>
            </ListSubheader>
          }
        >
          <ListItemButton onClick={() => navigate(PROTECTED_PATH)}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <DashboardOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
              primary="Dashboard"
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate(CATEGORY_PATH)}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <DraftsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
              primary="Categories"
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate(QUESTION_PATH)}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <QuestionAnswerOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
              primary="Questions"
            />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <PeopleAltOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
              primary="Users Manager"
            />
          </ListItemButton>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <InboxIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
              primary="Inbox"
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <StarBorder fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                  }}
                  primary="Starred"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
    </Stack>
  );
};

export default RightMenu;
