import * as yup from "yup";
export const schema = yup.object().shape({
  id: yup.mixed().nullable(),
  title: yup.string().required(),
  headContent: yup.string().nullable(),
  content: yup.string().required(),
  description: yup.string().required(),
  // response: yup.string().required(),
  response: yup.string().when("withSuggestions", {
    is: false,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  coefficient: yup.number().integer().positive(),
  duration: yup.number().integer().positive(),
  srouceCategoryId: yup.number().integer().positive(),
  parentCategoryId: yup.number().integer().positive(),
  categoryId: yup.number().integer().positive(),
  image: yup.string().nullable(),
  withSuggestions: yup.boolean(),
  suggestions: yup.mixed().when("withSuggestions", {
    is: true,
    then: (schema) =>
      yup
        .array()
        .of(yup.object().shape({ content: yup.string().required() }))
        .min(1),
    otherwise: (schema) => schema.nullable(),
  }),
});
