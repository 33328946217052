import { Box, BoxProps, List } from "@mui/material";
import { FC } from "react";
import { CategoryModel } from "../../../../../../models/category.model";
import { SubCategoriesListItem } from "./components/SubCategoriesListItem";

type SubCategoriesListingPropsModel = BoxProps & {
  data: CategoryModel[];
  level: number;
};
export const SubCategoriesListing: FC<SubCategoriesListingPropsModel> = ({
  data,
  level,
  ...boxProps
}) => {
  return (
    <Box sx={{ pl: 3, pb: 2 }} {...boxProps}>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {(data || []).map((item, index) => (
          <SubCategoriesListItem
            item={item}
            key={item.id}
            level={level}
            index={index}
          />
        ))}
      </List>
    </Box>
  );
};
