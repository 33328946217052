import { Box, Container, Stack } from "@mui/material";
import { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import TopAppBar from "./components/TopAppBar";
import RightMenu from "./components/RightMenu";
import { useAuthContext } from "../AuthProvider";
import { PUBLIC_PATH } from "../../constants/path";
import { RootBreadcrumbs } from "../../components/RootBreadcrumbs";

export const PrivateLayout: FC = () => {
  const auth = useAuthContext();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to={PUBLIC_PATH} state={{ from: location }} replace />;
  }

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{ bgcolor: "background.default" }}
    >
      <Stack direction={"row"} sx={{ height: "100vh" }}>
        <Box sx={{ width: 300 }}>
          <RightMenu />
        </Box>
        <Box sx={{ flexGrow: 1, height: "100%" }}>
          <TopAppBar />
          <Box p={3}>
            <RootBreadcrumbs />
          </Box>
          <Box p={3}>
            <Outlet />
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};
