import { IconButton } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { FC } from "react";
import { withConfirmationDilog } from "../../../../../../components/ConfirmationDialog";

const DeleteIconButton: FC = () => {
  return (
    <IconButton size="small" aria-label="delete" color="error">
      <DeleteOutlinedIcon fontSize="medium" />
    </IconButton>
  );
};

export const IconButtonDelete = withConfirmationDilog(DeleteIconButton);
