import httpClient from "../Lib/http-client";
import { UPLOAD_ENDPOINT } from "../constants/endpoint";

export const uploadApi = (file: File): Promise<string | null> => {
  const data = new FormData();
  if (file) {
    data.append("file", file as File);
  }

  return httpClient.post(UPLOAD_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
